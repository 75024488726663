const THEME_NAMES_ORDERED = [
  'Essential',
  'Black',
  'Pure',
  'Inverse',
  'Midnight',
  'Groundy',
  'Edge',
  'Monochrome',
  'Botanic',
  'Spark',
  'Photographic',
  'Modern',
];

const THEME_FAMILIES_NAMES_ORDERED = [
  'Essential',
  'Black',
  'Pure',
  'Modern',
  'Inverse',
  'Midnight',
  'Groundy',
  'Edge',
  'Monochrome',
  'Botanic',
  'Spark',
  'Photographic',
];

const THEME_BANNERS_ORDERED = [
  'Spark',
  'Midnight',
  'Pure',
  'Edge',
  'Essential',
  'Inverse',
  'Botanic',
  'Black',
  'Monochrome',
  'Photographic',
  'Modern',
  'Groundy',
];

module.exports = {
  THEME_NAMES_ORDERED,
  THEME_FAMILIES_NAMES_ORDERED,
  THEME_BANNERS_ORDERED,
};
