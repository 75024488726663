<template lang="pug">
Fragment
  router-view(v-if="$route.params.slug")
  Skeleton(v-else-if="loading")
  .template-chooser-collection-page(v-else)
    transition-group(name="fade" mode="out-in")
      Banner.themes-banner(
        v-if="!isSeasonal"
        key="themes-banner"
        :title="$t('templateChooser.banner.whatAreThemesTitle')"
        :description="$t('templateChooser.banner.whatAreThemesDescription')"
        @readMoreClick="openLink($t('templateChooser.banner.whatAreThemesReadMore'))"
        @watchAVideoClick="openLink('https://www.youtube.com/watch?v=qARHq6x-BsM')"
      )
    .grid-container(:class="{ 'mt-6': !isSeasonal }")
      template(v-if="isSeasonal")
        .grid-item(v-for="season in seasonalBanners")
          ThemeCard(
            :theme="season"
            :key="season.name"
            :name="season.name"
            :needToTrack="false"
            :to="getListingRoute('seasonal-templates', season.name)"
            @navigateWithFilterReset="$emit('navigateWithFilterReset', $event)"
          )
      template(v-else)
        .grid-item(v-for="theme in baseThemes")
          ThemeCard(
            :theme="theme"
            :key="theme.name"
            :name="theme.name"
            :needToTrack="false"
            :to="getListingRoute('themes-templates', `${theme.name}-${theme._id}`)"
            @navigateWithFilterReset="$emit('navigateWithFilterReset', $event)"
          )
</template>

<script>
  import { Fragment } from 'vue-frag';
  import { mapGetters, mapState } from 'vuex';
  import { THEME_BANNERS_ORDERED } from '@om/template-properties/src/theme/constants';

  export default {
    components: {
      Fragment,
      ThemeCard: () => import('@/components/TemplateChooser/components/cards/ThemeCard.vue'),
      SeasonCard: () => import('@/components/TemplateChooser/components/cards/SeasonCard.vue'),
      Banner: () => import('@/components/TemplateChooser/components/Banner.vue'),
      Skeleton: () => import('@/components/TemplateChooser/components/Skeleton.vue'),
    },
    computed: {
      ...mapState('templateChooser', ['themes']),
      ...mapGetters('templateChooser', ['seasonalBanners', 'loading']),
      baseThemes() {
        return THEME_BANNERS_ORDERED.map((name) =>
          this.themes.base.find((theme) => theme.name === name),
        ).filter(Boolean);
      },
      isSeasonal() {
        return this.$route.name.includes('seasonal');
      },
    },
    methods: {
      openLink(url) {
        window.open(url, '_blank').focus();
      },
      getListingRoute(name, slug) {
        return {
          name,
          params: { slug },
        };
      },
    },
  };
</script>

<style lang="sass" scoped>
  .grid-container
    display: grid
    grid-template-columns: auto auto
    gap: 2rem
    .grid-item
      overflow: hidden
      border-radius: 21px
      border: 1px solid #E4E3E5
      .brand-themes-card
        display: block
        border: unset
</style>
