import { render, staticRenderFns } from "./BannersCollection.vue?vue&type=template&id=ceaa5bd2&scoped=true&lang=pug&"
import script from "./BannersCollection.vue?vue&type=script&lang=js&"
export * from "./BannersCollection.vue?vue&type=script&lang=js&"
import style0 from "./BannersCollection.vue?vue&type=style&index=0&id=ceaa5bd2&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ceaa5bd2",
  null
  
)

export default component.exports